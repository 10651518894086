require('./bootstrap');

jQuery(document).ready(function ($) {
	var body = $('body');
	$('.header-slider').slick({
		dots: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: $(".arrows .right"),
		prevArrow: $(".arrows .left"),
	});

	let height = $(window).height() - $("nav.navbar").height();

	$(".header-slider .item").css('max-height', (height - 16) + 'px');

	$('.odds-slider').slick({
		slidesToShow: 8,
		slidesToScroll: 1,
		dots: false,
		nextArrow: $(".arrows .right"),
		prevArrow: $(".arrows .left"),
		swipe: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 6,
					slidesToScroll: 1,
					dots: false,
				}
			},
			{
				breakpoint: 728,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
					dots: false,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
					dots: false,
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					dots: false,
				}
			}
		]
	});

	$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
		$('.odds-slider').slick('setPosition');
	});

	/*body.on('click', '.main-header a', function(e){
		e.preventDefault();
		var that = $(this);
		if( !that.hasClass('active') ){
			var active = $( that.attr('href') );
			that.siblings().removeClass('active');
			active.siblings().addClass('d-none');
			that.addClass('active');
			active.removeClass('d-none');
		}
	});*/

	body.on('click', '.match-predict', function(e){
		e.preventDefault();
		var that = $(this),
			modal = $('#match-predict')
		;
		$.get( that.attr('href'), function( data ) {
			var mbody = modal.find('.modal-body');
			mbody.empty();
			mbody.append(data);
			modal.modal('show');
		});
	});

	body.on('click', '.get-ticker', function(e){
		e.preventDefault();
		var that = $(this);
		$(e.target).append(`<i class="fa fa-spinner fa-spin"></i>`);
		$.get( that.attr('href'), function( data ) {
			var mbody = $("#ticker-data");
			mbody.empty();
			mbody.append(data);
			$("#events .ref-btn").html("<button href='" + that.attr('href') + "' class='get-ticker btn btn-success btn-sm'><i class='fa fa-refresh' aria-hidden='true'></i></button>");
		});
	});

	body.on('click', '.get-lineups', function(e){
		e.preventDefault();
		$(e.target).append(`<i class="fa fa-spinner fa-spin"></i>`);
		var that = $(this);
		$.get( that.attr('href'), function( data ) {
			var mbody = $("#lineups-data");
			mbody.empty();
			mbody.append(data);
		});
	});

	body.on('click', '.vote', function(e){
		e.preventDefault();
		var that = $(this);
		$.post( that.attr('href'), {
			_token: $("meta[name='csrf-token']").attr('content'),
		},  function( res ) {
			if(res == 'success'){
				var count = that.parent().find('.count').text();
				count = parseInt(count) + 1;
				that.parent().find('.count').text(count);
			}else{
				alert(res);
			}
		});
	});

	body.on('click', '#c-btn', function(e){
		e.preventDefault();
		var that = $(this);
		that.toggleClass('fa-times');
		$('#country-filter').slideToggle();
	});
	body.on('keyup', '#country-filter', function(e){
		e.preventDefault();
		var that = $(this);
		var value = that.val().toLowerCase();
		$("#countries > li > a").filter(function() {
			var that2 = $(this);
			that2.toggle(that2.text().toLowerCase().indexOf(value) > -1)
		});
	});

	body.on('click', '.sidebar .list-group > li > a > .fa', function (e) {
		e.preventDefault();
		var that = $(this),
			a = $(this).parent()
		;

		if (that.hasClass('fa-angle-down')) {
			that.addClass('fa-angle-up').removeClass('fa-angle-down');
			a.siblings('.submenu').removeClass('d-none');
		} else {
			that.addClass('fa-angle-down').removeClass('fa-angle-up');
			a.siblings('.submenu').addClass('d-none');
		}


	});

	body.on('click', '.groups', function () {
		$('.groups').removeClass('active');
		$('.category').addClass('d-none');
		$(this).addClass('active');
		$('#' + $(this).data('id')).removeClass('d-none');
	});

	/*$.each( body.find('.lazy'), function( i, tag ) {
		$(tag).attr('src', $(tag).attr('data-src'));
	});*/

	$(".section-question-answer").find(".item").on("click", function () {
		$(this).children(".answer").slideToggle(), $(this).hasClass("open") ? $(this).removeClass("open") : $(this).addClass("open")
	})
});
